html {
    background: black;
}

body {
    font-family: sans-serif;
    font-size: 11pt;
}

* {
    user-select: none;
}

.selectable {
    user-select: text;
}

textarea,
select,
input[type=number],
input[type=text] {
    background: #555;
    color: white;
    border: 1px solid black;
    padding: 3px 3px;
    flex: 1 1 auto;
}

select {
    padding: 2px 0;
    min-width: 0;
    width: auto;
    max-width: auto;
}

textarea {
    height: auto;
    min-height: 20em;
}

input[type=checkbox] {
    align-self: center;
    width: 1.1em;
    height: 1.1em;
}

select:focus,
input[type=number]:focus,
input[type=text]:focus {
    border-color: white;
}

select:disabled,
input[type=number]:disabled,
input[type=text]:disabled {
    border-color: black;
    background-color: #222;
    color: #555;
}

select:invalid,
input[type=number]:invalid,
input[type=text]:invalid {
    border-color: #f88;
    background-color: #422;
    color: #f88;
}

input[type=range],
input[type=text],
input[type=number] {
    width: 100%;
}

input[type=text],
input[type=number] {
    min-width: 4em;
}

button {
    padding: 2px 4px;
    min-width: 1.5em;
    cursor: pointer;
    position: relative;
    display: inline-flex;
    justify-content: center;
}

input[type=file] {
    position: absolute;
    inset: 0 0 0 0;
    opacity: 0;
}

input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button { /* chromes and blink button */
    cursor: pointer;
}

input[type=file]:disabled, /* FF, IE7+, chrome (except button) */
input[type=file]:disabled::-webkit-file-upload-button { /* chromes and blink button */
    cursor: default;
}

button.symbol, button.bad-symbol, button.good-symbol {
    color: #bbb;
}

button.good, button.bad {
    color: white;
    padding: 4px 8px;
    border: 1px solid #000;
}

button.good {
    background-color: #036;
    border-color: #06c;
}

button.bad {
    background-color: #600;
    border-color: #c00;
}

button.good-symbol {
    color: #8bf;
}

button.bad-symbol {
    color: #f88;
}

button.symbol:focus,
button.good-symbol:focus,
button.bad-symbol:focus,
button.symbol:hover,
button.good-symbol:hover,
button.bad-symbol:hover {
    color: white;
}

button.good:focus, button.good:hover, button.bad:focus, button.bad:hover {
    border-color: white;
}

button.symbol:active {
    color: #666;
}

button.bad-symbol:active {
    color: #600;
}
button.good-symbol:active {
    color: #036;
}

button.good:active {
    background-color: #024;
}
button.bad:active {
    background-color: #400;
}

button:disabled {
    color: #888 !important;
    cursor: default !important;
    border-color: #222 !important;
}

button.good:disabled, button.bad:disabled {
    background-color: #444 !important;
}


code {
    font-family: monospace;
}

a, button.link {
    color: #8cf;
    text-decoration: underline;
}
button.link:hover, button.link:focus, a:hover, a:focus {
    color: white;
}

#app {
    display: grid;
    grid-template-columns: 4fr;
    grid-auto-columns: auto;
    grid-auto-flow: column;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;
    color: #ccc;
}

#modalwrap {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #0008;
    display: grid;
    align-items: center;
    justify-items: center;
}

.layeroptions, .filteroptions {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-columns: min-content;
    grid-auto-flow: column;
    justify-content: flex-end;
}

#toggle {
    display: grid;
    align-items: center;
    color: white;
    width: 20px;
}

#toggle button {
    padding: 32px 4px;
    background: #444;
    color: #ccc;
}
#toggle button:hover {
    color: white;
    background: #555;
}

#viewport {
    display: grid;
    background: black;
    position: relative;
}

#canvas {
    width: 100%;
    padding: 8px;
    max-height: 99vh;
    margin: auto;
    display: grid;
    align-self: center;
    justify-self: center;
    box-shadow: 0px 8px 8px black;
}

#controlpanel {
    background: #333;
    color: #ccc;
    overflow-y: scroll;
    padding: 8px;
    width: 480px;
    max-width: calc(100vw - 36px);
}

#export {
    grid-area: 1 / 1;
    stroke-linejoin: round;
    align-self: center;
    justify-self: center;
    max-height: 100%;
}

.layer, .filter {
    border: 1px solid black;
    margin-bottom: 4px;
}

.effecttitle {
    display: grid;
    grid-template-columns: 1fr min-content;
    border-bottom: 1px solid currentColor;
}

.effecttype {
    font-size: 14pt;
    font-variant: small-caps;
}

.layertitle {
    display: grid;
    grid-template-columns: min-content min-content 1fr auto min-content;
    grid-template-rows: 1fr 1fr;
    gap: 2px;
    align-items: center;
    background: #222;
    padding: 2px 4px;
    align-items: center;
    justify-items: center;
}


.layerfold {
    grid-row: 1 / -1;
    grid-column: 1;
    padding: 4px;
    cursor: pointer;
}

.layervis {
    grid-row: 2;
    grid-column: 2;
}

.layercmd {
    grid-row: 2;
    grid-column: 4;
    display: grid;
    grid-auto-columns: min-content;
    grid-auto-flow: column;
    align-items: center;
    justify-items: center;
}

.layersort {
    grid-column: 5;
}
.layersort.sortup {
    grid-row: 1
}
.layersort.sortdn {
    grid-row: 2
}

.layername {
    grid-row: 1;
    grid-column: 2 / span 3;
    justify-self: stretch;
    font-size: 12pt;
}

input.layername_field {
    background: #222;
    border-width: 0;
    border-bottom: 1px solid black;
}

.layertype {
    grid-row: 2;
    grid-column: 3;
    justify-self: stretch;
    padding: 0 4px;
    font-size: 10pt;
    color: #aaa;
}

.newlayer {
    border: 1px dashed black;
    padding: 4px;
    display: grid;
    gap: 8px;
    margin-bottom: 4px;
    grid-template-columns: repeat(3, 1fr);
}

.options {
    font-size: 9pt;
    display: grid;
    gap: 4px;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
}

.newlayeroptions {
    font-size: 9pt;
    display: grid;
    gap: 4px;
    grid-template-columns: 1fr;
    grid-auto-columns: min-content;
    grid-auto-flow: column;
    grid-column-start: 1;
    grid-column-end: -1;
}

.controls {
    display: grid;
    grid-template-columns: 1fr;
    padding: 8px;
    gap: 8px 0;
    width: 100%;
}

.field {
    display: grid;
    grid-template-rows: auto;
    grid-auto-columns: auto;
    grid-auto-flow: column;
    padding: 2px;
    margin: 4px;
}

.field.with-label {
    grid-template-rows: auto auto;
}

.field.with-inline-label {
    grid-template-columns: 6em;
}

.field_label {
    font-size: 9pt;
    display: flex;
    grid-column: 1 / -1;
    align-items: center;
    justify-content: flex-start;
    padding: 0 1px;
    overflow-x: hidden;
    white-space: nowrap;
}

.field_content {
    display: grid;
    grid-auto-columns: auto;
    grid-auto-flow: column;
    gap: 4px 2px;
}

.group {
    grid-column-start: 1;
    grid-column-end: -1;
    display: grid;
    background: #222;
}

.group_label {
    padding: 4px;
    font-size: 9pt;
    font-variant: small-caps;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 2px;
}

.group_content {
    display: grid;
    grid-auto-columns: 1fr;
}

.row {
    display: grid;
    gap: 0px;
    margin: 1px;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    align-items: center;
}

.row.with-label {
    grid-template-columns: 3em;
}

.row_label {
    text-align: right;
    font-variant: small-caps;
    font-size: 10pt;
    padding: 4px;
    display: grid;
    align-items: center;
    justify-content: center;
}

.tabs {
    border: 1px solid black;
    display: grid;
    grid-template-rows: min-content auto;
}

.tabs_menu {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    gap: 4px;
    padding: 2px 4px;
}

.tabs_option {
    background: #222;
}

.tabs_option.option-active {
    background: #444;
}

.tabs_content {
    background: #444;
}

.heading {
    font-size: 14pt;
    font-variant: small-caps;
    border-bottom: 1px solid currentColor;
    grid-column: 1 / -1;
}

.subheading {
    font-size: 11pt;
    font-variant: small-caps;
    border-bottom: 1px solid currentColor;
    grid-column: 1 / -1;
    text-align: right;
}

.inputwrapper {
    display: flex;
    width: auto;
    align-items: stretch;
    position: relative;
    gap: 4px;
    min-width: 0;
}

.checkbox_label {
    font-size: 10pt;
    align-self: center;
}

.layerlist {
    padding: 4px 0;
}

.icon {
    width: 1.2em;
    height: 1em;
    display: inline-grid;
    vertical-align: baseline;
    position: relative;
    align-content: center;
    justify-content: center;
}
.icon.large {
    font-size: 200%;
}

.icon svg {
    position: absolute;
    top: -0.1em;
    bottom: -0.1em;
    left: 0;
    right: 0;
    width: 1.2em;
    height: 1.2em;
    fill: currentColor;
}

.warning {
    border: 1px solid #fc3;
    font-size: 10pt;
}

.warning_label {
    padding: 4px;
    background: #541;
    color: white;
    font-size: 12pt;
    font-variant: small-caps;
}

.warning_content {
    padding: 8px;
}

p {
    margin-bottom: 0.5em;
}
p:last-child {
    margin-bottom: 0;
}
.warning .highlight {
    color: #fc3;
}

.newlayer_warning {
    margin: 0 4px;
}

.modal {
    background: #111;
    width: 480px;
    max-height: 90%;
    display: grid;
    grid-template-rows: min-content 1fr;
    border: 1px solid #666;
    padding: 4px;
    border-radius: 8px;
}

.modal_title {
    font-size: 14pt;
    font-variant: small-caps;
    display: grid;
    grid-template-columns: 1fr min-content;
    padding: 8px;
    color: white;
}

.modal_content {
    padding: 16px;
    overflow-y: auto;
}

.changelog_version {
    font-size: 12pt;
    border-bottom: 1px solid #ccc;
    color: #fc3;
}

.changelog_item {
    font-size: 10pt;
}

li {
    list-style-position: outside;
    list-style-type: disc;
    margin: 8px;
    margin-left: 1.5em;
}

li:before {

}

.splineinput {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    padding: 0.5em;
    gap: 1em;
}

.splineinput_canvas, .splineinput_selector {
    width: 100%;
    height: 6em;
    grid-area: 1 / 1;
    cursor: crosshair;
    position: relative;
}
.splineinput_selector {
    outline: 1px solid #444;
}

.splineinput_line {
    stroke: #888;
    stroke-width: 1.5px;
    vector-effect: non-scaling-stroke;
    fill: none;
}

.gradientinput {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    padding: 0.5em;
}

.gradientinput_canvas {
    width: 100%;
    height: 2em;
    cursor: crosshair;
}

.gradientinput_selector {
    position: relative;
    width: 100%;
    height: 2em;
}

.gradientinput_color, .splineinput_vert {
    width: 1em;
    height: 1em;
    display: grid;
    align-items: center;
    justify-content: center;
    position: absolute;
    color: #888;
    cursor: pointer;
}

.gradientinput_color.state-selected, .splineinput_vert.state-selected {
    color: #fff;
}

.gradientinput_noselection, .splineinput_noselection {
    font-size: 10pt;
    font-style: italic;
    border: 1px dashed #555;
    padding: 12px;
    display: grid;
    align-items: center;
    justify-content: center;
}
